// ==========================================================================
//
// Thumbs
// Displays thumbnails in a grid
//
// ==========================================================================
;(function (document, $) {
	'use strict';

	var FancyThumbs = function( instance ) {

		this.instance = instance;

		this.init();

	};

	$.extend( FancyThumbs.prototype, {

		$button		: null,
		$grid		: null,
		$list		: null,
		isVisible	: false,

		init : function() {
			var self = this;

			self.$button = $('<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs" title="Thumbnails (G)"></button>')
				.appendTo( this.instance.$refs.buttons )
				.on('touchend click', function(e) {
					e.stopPropagation();
					e.preventDefault();

					self.toggle();
				});

		},

		create : function() {
			var instance = this.instance,
				list,
				src;

			this.$grid = $('<div class="fancybox-thumbs"></div>').appendTo( instance.$refs.container );

			list = '<ul>';

			$.each(instance.group, function( i, item ) {

				src = item.opts.thumb || ( item.opts.$thumb ? item.opts.$thumb.attr('src') : null );

				if ( !src && item.type === 'image' ) {
					src = item.src;
				}

				if ( src && src.length ) {
					list += '<li data-index="' + i + '"  tabindex="0" class="fancybox-thumbs-loading"><img data-src="' + src + '" /></li>';
				}

			});

			list += '</ul>';

			this.$list = $( list ).appendTo( this.$grid ).on('click touchstart', 'li', function() {

				instance.jumpTo( $(this).data('index') );

			});

			this.$list.find('img').hide().one('load', function() {

				var $parent		= $(this).parent().removeClass('fancybox-thumbs-loading'),
					thumbWidth	= $parent.outerWidth(),
					thumbHeight	= $parent.outerHeight(),
					width,
					height,
					widthRatio,
					heightRatio;

				width  = this.naturalWidth	|| this.width;
				height = this.naturalHeight	|| this.height;

				//Calculate thumbnail width/height and center it

				widthRatio  = width  / thumbWidth;
				heightRatio = height / thumbHeight;

				if (widthRatio >= 1 && heightRatio >= 1) {
					if (widthRatio > heightRatio) {
						width  = width / heightRatio;
						height = thumbHeight;

					} else {
						width  = thumbWidth;
						height = height / widthRatio;
					}
				}

				$(this).css({
					width         : Math.floor(width),
					height        : Math.floor(height),
					'margin-top'  : Math.min( 0, Math.floor(thumbHeight * 0.3 - height * 0.3 ) ),
					'margin-left' : Math.min( 0, Math.floor(thumbWidth  * 0.5 - width  * 0.5 ) )
				}).show();

			})
			.each(function() {
				this.src = $( this ).data( 'src' );
			});

		},

		focus : function() {

			if ( this.instance.current ) {
				this.$list
					.children()
					.removeClass('fancybox-thumbs-active')
					.filter('[data-index="' + this.instance.current.index  + '"]')
					.addClass('fancybox-thumbs-active')
					.focus();
			}

		},

		close : function() {

			this.$grid.hide();

		},

		update : function() {

			this.instance.$refs.container.toggleClass('fancybox-container--thumbs', this.isVisible);

			if ( this.isVisible ) {

				if ( !this.$grid ) {
					this.create();
				}

				this.$grid.show();

				this.focus();

			} else if ( this.$grid ) {
				this.$grid.hide();
			}

			this.instance.update();

		},

		hide : function() {

			this.isVisible = false;

			this.update();

		},

		show : function() {

			this.isVisible = true;

			this.update();

		},

		toggle : function() {

			if ( this.isVisible ) {
				this.hide();

			} else {
				this.show();
			}
		}

	});

	$(document).on('onInit.fb', function(e, instance) {
		var first  = instance.group[0],
			second = instance.group[1];

		if ( !!instance.opts.thumbs && !instance.Thumbs && instance.group.length > 1 && (
		    		( first.type == 'image'  || first.opts.thumb  || first.opts.$thumb ) &&
		    		( second.type == 'image' || second.opts.thumb || second.opts.$thumb )
			 	)
		   ) {

			instance.Thumbs = new FancyThumbs( instance );
		}

	});

	$(document).on('beforeMove.fb', function(e, instance, item) {
		var self = instance && instance.Thumbs;

		if ( !self ) {
			return;
		}

		if ( item.modal ) {

			self.$button.hide();

			self.hide();

		} else {

			if ( instance.opts.thumbs.showOnStart === true && instance.firstRun ) {
				self.show();

			}

			self.$button.show();

			if ( self.isVisible ) {
				self.focus();
			}

		}

	});

	$(document).on('beforeClose.fb', function(e, instance) {

		if ( instance && instance.Thumbs) {
			if ( instance.Thumbs.isVisible && instance.opts.thumbs.hideOnClosing !== false ) {
				instance.Thumbs.close();
			}

			instance.Thumbs = null;
		}

	});

}(document, window.jQuery));

// ==========================================================================
//
// FullScreen
// Adds fullscreen functionality
//
// ==========================================================================
;(function (document, $) {
	'use strict';

	// Collection of methods supported by user browser
	var fn = (function () {

		var fnMap = [
			[
				'requestFullscreen',
				'exitFullscreen',
				'fullscreenElement',
				'fullscreenEnabled',
				'fullscreenchange',
				'fullscreenerror'
			],
			// new WebKit
			[
				'webkitRequestFullscreen',
				'webkitExitFullscreen',
				'webkitFullscreenElement',
				'webkitFullscreenEnabled',
				'webkitfullscreenchange',
				'webkitfullscreenerror'

			],
			// old WebKit (Safari 5.1)
			[
				'webkitRequestFullScreen',
				'webkitCancelFullScreen',
				'webkitCurrentFullScreenElement',
				'webkitCancelFullScreen',
				'webkitfullscreenchange',
				'webkitfullscreenerror'

			],
			[
				'mozRequestFullScreen',
				'mozCancelFullScreen',
				'mozFullScreenElement',
				'mozFullScreenEnabled',
				'mozfullscreenchange',
				'mozfullscreenerror'
			],
			[
				'msRequestFullscreen',
				'msExitFullscreen',
				'msFullscreenElement',
				'msFullscreenEnabled',
				'MSFullscreenChange',
				'MSFullscreenError'
			]
		];

		var val;
		var ret = {};
		var i, j;

		for ( i = 0; i < fnMap.length; i++ ) {
			val = fnMap[ i ];

			if ( val && val[ 1 ] in document ) {
				for ( j = 0; j < val.length; j++ ) {
					ret[ fnMap[ 0 ][ j ] ] = val[ j ];
				}

				return ret;
			}
		}

		return false;
	})();

	if ( !fn ) {
		return;
	}

	var FullScreen = {
		request : function ( elem ) {

			elem = elem || document.documentElement;

			elem[ fn.requestFullscreen ]( elem.ALLOW_KEYBOARD_INPUT );

		},
		exit : function () {
			document[ fn.exitFullscreen ]();
		},
		toggle : function ( elem ) {

			if ( this.isFullscreen() ) {
				this.exit();
			} else {
				this.request( elem );
			}

		},
		isFullscreen : function()  {
			return Boolean( document[ fn.fullscreenElement ] );
		},
		enabled : function()  {
			return Boolean( document[ fn.fullscreenEnabled ] );
		}
	};

	$(document).on({
		'onInit.fb' : function(e, instance) {
			var $container;

			if ( instance && !!instance.opts.fullScreen && !instance.FullScreen) {
				$container = instance.$refs.container;

				instance.$refs.button_fs = $('<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fullscreen" title="Full screen (F)"></button>')
					.appendTo( instance.$refs.buttons );

				$container.on('click.fb-fullscreen', '[data-fancybox-fullscreen]', function(e) {

					e.stopPropagation();
					e.preventDefault();

					FullScreen.toggle( $container[ 0 ] );

				});

				if ( instance.opts.fullScreen.requestOnStart === true ) {
					FullScreen.request( $container[ 0 ] );
				}

			}

		}, 'beforeMove.fb' : function(e, instance) {

			if ( instance && instance.$refs.button_fs ) {
				instance.$refs.button_fs.toggle( !!instance.current.opts.fullScreen );
			}

		}, 'beforeClose.fb':  function() {
			FullScreen.exit();
		}
	});

	$(document).on(fn.fullscreenchange, function() {
		var instance = $.fancybox.getInstance();
		var  $what   = instance ? instance.current.$placeholder : null;

		if ( $what ) {

			// If image is zooming, then this will force it to stop and reposition properly
			$what.css( 'transition', 'none' );

			instance.isAnimating = false;

			instance.update( true, true, 0 );
		}

	});

}(document, window.jQuery));
